import { FormattedMessage } from "react-intl"

export const footerLinks = [

    {
        title: "KosovaKids",
        links : [
            {
                link: <FormattedMessage id="f1"  />,
                whereTo: "/aboutus"
            },
            {
                link: <FormattedMessage id="f2"  />,
                whereTo: "/ourTeam"
            },

            {
                link: <FormattedMessage id="footer__f1"  />,
                whereTo: "/ourCenters"
            },
            // {
            //     link: <FormattedMessage id="footer__f2"  />,
            //     whereTo: "/pricing"
            // },

            {
                link: <FormattedMessage id="footer__f3"  />,
                whereTo: "/ourhistory"
            },

            {
                link: <FormattedMessage id="footer__f4"  />,
                whereTo: "/gallery"
            }
        ]
    },
    {
        title: <FormattedMessage id="f9"  />,
        links : [
            {
                link: <FormattedMessage id="footer__f5"  />,
                whereTo: "/ourCourses"
            },

            {
                link: <FormattedMessage id="footer__f6"  />,
                whereTo: "/"
            },

            {
                link: <FormattedMessage id="footer__f7"  />,
                whereTo: "/"
            },

            {
                link: <FormattedMessage id="footer__f8"  />,
                whereTo: "/"
            },

            {
                link: <FormattedMessage id="footer__f9"  />,
                whereTo: "/"
            },

            {
                link: <FormattedMessage id="footer__f10"  />,
                whereTo: "/"
            },

            {
                link: <FormattedMessage id="footer__f11"  />,
                whereTo: "/freeCourses"
            }
        ]
    },
    {
        // title: <FormattedMessage id="f18" />,
        title: <FormattedMessage id="footer__f12"  />,
        links : [
            // {
            //     link: <FormattedMessage id="f18" />
            //   },
            //   {
            //     link: <FormattedMessage id="f19" />
            //   },
            //   {
            //     link: <FormattedMessage id="f20" />
            //   },
            //   {
            //     link: <FormattedMessage id="f21" />
            //   },
            //   {
            //     link: <FormattedMessage id="f22" />
            //   },
            //   {
            //     link: <FormattedMessage id="f23" />
            //   },
            //   {
            //     link: <FormattedMessage id="f24" />
            //   },
            //   {
            //     link: <FormattedMessage id="f25" />
            //   }

            {
                link: <FormattedMessage id="footer__f13"  />,
                whereTo: "/dayCare"
            },

            {
                link: <FormattedMessage id="footer__f14"  />,
                whereTo: "/weekEndStay"
            },

            {
                link: <FormattedMessage id="footer__f15"  />,
                whereTo: "/nightStay"
            },

            {
                link: <FormattedMessage id="footer__f16"  />,
                whereTo: "/halfDayCare"
            },

            {
                link: <FormattedMessage id="footer__f17"  />,
                whereTo: "/outdoorActivities"
            },

            {
                link: <FormattedMessage id="footer__f18"  />,
                whereTo: "/stayAfterRegular"
            }
        ]
    },
    {
        // title: <FormattedMessage id="f27" />,
        title: <FormattedMessage id="footer__f19"  />,
        links : [
            // {
            //     link: <FormattedMessage id="f28" />
            //   },
            //   {
            //     link: <FormattedMessage id="f29" />
            //   },
            //   {
            //     link: <FormattedMessage id="f30" />
            //   },
            //   {
            //     link: <FormattedMessage id="f31" />,
            //     whereTo: "/blog"
            //   },
            //   {
            //     link: <FormattedMessage id="f32" />
            //   },
            //   {
            //     link: <FormattedMessage id="f33" />
            //   },
            //   {
            //     link: <FormattedMessage id="f34" />
            //   },
            //   {
            //     link: <FormattedMessage id="f35" />
            //   }

            {
                link: <FormattedMessage id="footer__f20"  />,
                whereTo: "/contactUs"
            },

            {
                link: <FormattedMessage id="footer__f21"  />,
                whereTo: "/apply"
            },

            {
                link: <FormattedMessage id="footer__f22"  />,
              whereTo: "/blog"
            },

            // {
            //     link: <FormattedMessage id="footer__f23"  />,
            //     whereTo: "/donate"
            // },

            {
                link: <FormattedMessage id="footer__f24"  />,
                whereTo: "/"
            },

            {
                link: <FormattedMessage id="footer__f25"  />,
                whereTo: "/"
            }
        ]
    }
]