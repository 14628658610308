import React, { useEffect, useState } from "react";
import "./CoursesDetails.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/shared/Navbar/Navbar";
import Footer from "../../components/shared/Footer/Footer";
import { IoIosArrowRoundBack, IoIosPricetag } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaClock, FaUser } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { CiUser } from "react-icons/ci";
import { FormattedMessage } from "react-intl";

const CoursesDetails = () => {
  const { id } = useParams();

  const [pageData, setPageData] = useState([]);
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [phonenumber, setphonenumber] = useState('');
  const [email, setemail] = useState('');
  const [firstname2, setfirstname2] = useState('');
  const [lastname2, setlastname2] = useState('');




  




  const fetchData = async () => {
    try {
      const storedLocale = localStorage.getItem("locale");
      const supportedLocales = ["en-GB", "sq-AL"];
      const defaultLocale = "en-GB";
      const locale = supportedLocales.includes(storedLocale)
        ? storedLocale
        : defaultLocale;
      const api = `/API/OurCourses-${locale}.json`;

      const response = await fetch(api);
      const data = await response.json();
      setPageData(data);
    } catch (error) {
      console.error("Blog : ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [localStorage.getItem("language")]);

  const item = pageData.find((item) => item.id === Number(id)) || pageData;

  const bgImageDiv = {
    backgroundImage: `url(${process.env.REACT_APP_OurCoursesImages_IMAGE}${item.upperImage})`,
  };

  return (
    <div className="CoursesDetails">
      <Navbar />

      <div className="CoursesDetails__inside">
        <div className="CoursesDetails__1" style={bgImageDiv}>
          <h1>{item.childish}</h1>
          <Link to="/ourCourses" className="backInPage">
            <IoIosArrowRoundBack />
            <FormattedMessage
              id="CoursesDetails__1"
              defaultMessage={"Back To Courses"}
            />
          </Link>
          <div className="CoursesDetails__1Filter"></div>
        </div>

        <div className="CoursesDetails__2">
          <Link className="enroll" to={"/apply"}>
            <FormattedMessage
              id="CoursesDetails__2"
              defaultMessage={"Enroll"}
            />
          </Link>

          <h1 className="CoursesDetailsTitle">
            <FormattedMessage
              id="CoursesDetails__3"
              defaultMessage={"Course Description"}
            />
          </h1>

          <p className="CoursesDetailsText">{item.bigDescription}</p>

          <div className="items">
            <div className="item">
              <FaRegCalendarAlt />

              <div className="right">
                <h4>
                  <FormattedMessage
                    id="CoursesDetails__4"
                    defaultMessage={"Duration"}
                  />
                </h4>

                <p>{item.durationTime}</p>

                <p>{item.sessionPerWeek}</p>
              </div>
            </div>

            <div className="item">
              <FaClock />

              <div className="right">
                <h4>
                  <FormattedMessage
                    id="CoursesDetails__5"
                    defaultMessage={"Schedule"}
                  />
                </h4>

                <p>{item.hoursPerSession}</p>

                <p>{item.starAndEntTime}</p>
              </div>
            </div>

            <div className="item">
              <IoIosPricetag />

              <div className="right">
                <h4>
                  <FormattedMessage
                    id="CoursesDetails__6"
                    defaultMessage={"Total Price"}
                  />
                </h4>
                <p>{item.totalPrice}</p>
              </div>
            </div>

            <div className="item">
              <FaUser />

              <div className="right">
                <h4>
                  <FormattedMessage
                    id="CoursesDetails__7"
                    defaultMessage={"Age"}
                  />
                </h4>

                <p>{item.age}</p>
              </div>
            </div>

            <div className="item">
              <div className="instructor"></div>

              <div className="right">
                <h4>
                  <FormattedMessage
                    id="CoursesDetails__8"
                    defaultMessage={"Instructor"}
                  />
                </h4>

                <p>{item.instructor}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="CoursesDetails__3">
          <h1 className="CoursesDetailsTitle">
            <FormattedMessage
              id="CoursesDetails__9"
              defaultMessage={"Curriculum"}
            />
          </h1>

          <div className="CurriculumCards">
            {/* Week 1 */}
            {item.curriculum1 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum1}</h3>
                  <p className="whatWeek">{item.curriculum1Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum1Detail__1}</li>
                  <li>{item.curriculum1Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 1 */}

            {/* Week 2 */}
            {item.curriculum2 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum2}</h3>
                  <p className="whatWeek">{item.curriculum2Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum2Detail__1}</li>
                  <li>{item.curriculum2Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 2 */}

            {/* Week 3 */}
            {item.curriculum3 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum3}</h3>
                  <p className="whatWeek">{item.curriculum3Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum3Detail__1}</li>
                  <li>{item.curriculum3Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 3 */}

            {/* Week 4 */}
            {item.curriculum4 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum4}</h3>
                  <p className="whatWeek">{item.curriculum4Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum4Detail__1}</li>
                  <li>{item.curriculum4Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 4 */}

            {/* Week 5 */}
            {item.curriculum5 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum5}</h3>
                  <p className="whatWeek">{item.curriculum5Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum5Detail__1}</li>
                  <li>{item.curriculum5Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 5 */}

            {/* Week 6 */}
            {item.curriculum6 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum6}</h3>
                  <p className="whatWeek">{item.curriculum6Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum6Detail__1}</li>
                  <li>{item.curriculum6Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 6 */}

            {/* Week 7 */}
            {item.curriculum7 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum7}</h3>
                  <p className="whatWeek">{item.curriculum7Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum7Detail__1}</li>
                  <li>{item.curriculum7Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 7 */}

            {/* Week 8 */}
            {item.curriculum8 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum8}</h3>
                  <p className="whatWeek">{item.curriculum8Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum8Detail__1}</li>
                  <li>{item.curriculum8Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 8 */}

            {/* Week 9 */}
            {item.curriculum9 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum9}</h3>
                  <p className="whatWeek">{item.curriculum9Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum9Detail__1}</li>
                  <li>{item.curriculum9Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 9 */}

            {/* Week 10 */}
            {item.curriculum10 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum10}</h3>
                  <p className="whatWeek">{item.curriculum10Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum10Detail__1}</li>
                  <li>{item.curriculum10Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 10 */}

            {/* Week 11 */}
            {item.curriculum11 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum11}</h3>
                  <p className="whatWeek">{item.curriculum11Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum11Detail__1}</li>
                  <li>{item.curriculum11Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 11 */}

            {/* Week 12 */}
            {item.curriculum12 && (
              <div className="CurriculumCard">
                <div className="top">
                  <h3 className="week">{item.curriculum12}</h3>
                  <p className="whatWeek">{item.curriculum12Detail}</p>
                </div>
                <ul className="bottom">
                  <li>{item.curriculum12Detail__1}</li>
                  <li>{item.curriculum12Detail__2}</li>
                </ul>
              </div>
            )}
            {/* END Week 12 */}
          </div>
        </div>

        <div className="CoursesDetails__4">
          <h1 className="CoursesDetailsTitle">
            <FormattedMessage
              id="CoursesDetails__10"
              defaultMessage={"Teaching Methodology"}
            />
          </h1>

          <div className="TeachingCards">
            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon1"></div>
              <p className="TeachingCardTitle">
                <FormattedMessage
                  id="CoursesDetails__11"
                  defaultMessage={"Interactive Learning:"}
                />
              </p>
              <p className="TeachingCardText">
                <FormattedMessage
                  id="CoursesDetails__12"
                  defaultMessage={
                    "Utilizing platforms like Scratch to make coding visual and engaging for kids to understand easier."
                  }
                />
              </p>
            </div>

            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon2"></div>
              <p className="TeachingCardTitle">
                <FormattedMessage
                  id="CoursesDetails__13"
                  defaultMessage={"Project-Based:"}
                />
              </p>
              <p className="TeachingCardText">
                <FormattedMessage
                  id="CoursesDetails__14"
                  defaultMessage={
                    "Encouraging hands-on learning through projects that allow creativity and application of learned concepts."
                  }
                />
              </p>
            </div>

            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon3"></div>
              <p className="TeachingCardTitle">
                <FormattedMessage
                  id="CoursesDetails__15"
                  defaultMessage={"Collaborative Environment:"}
                />
              </p>
              <p className="TeachingCardText">
                <FormattedMessage
                  id="CoursesDetails__16"
                  defaultMessage={"Collaborative Environment:"}
                />
              </p>
            </div>

            <div className="TeachingCard">
              <div className="TeachingCardIcon TeachingCardIcon4"></div>
              <p className="TeachingCardTitle">
                <FormattedMessage
                  id="CoursesDetails__17"
                  defaultMessage={"Supportive Feedback:"}
                />
              </p>
              <p className="TeachingCardText">
                <FormattedMessage
                  id="CoursesDetails__18"
                  defaultMessage={
                    "Offering constructive feedback and encouragement to promote a positive learning experience. "
                  }
                />
              </p>
            </div>
          </div>
        </div>

        <div className="CoursesDetails__5">
          <h1 className="CoursesDetailsTitle">
            <FormattedMessage
              id="CoursesDetails__19"
              defaultMessage={"What to expect from this course?"}
            />
          </h1>

          <div className="CoursesDetails__5__inside">
            <div className="top">
              <div className="left">
                <p className="whatP">
                  <FormattedMessage
                    id="CoursesDetails__20"
                    defaultMessage={"Students will:"}
                  />
                </p>

                <div className="whatQuestions">
                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder1">
                      <div className="whatImage whatImage1"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__21"
                          defaultMessage={
                            "Gain a Strong Foundation in Programming"
                          }
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__22"
                          defaultMessage={
                            "By the end of the course, students will understand basic programming concepts and how to apply them to create their own projects"
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder2">
                      <div className="whatImage whatImage2"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__23"
                          defaultMessage={
                            "Develop Critical Thinking and Problem-Solving Skills:"
                          }
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__24"
                          defaultMessage={
                            "Through coding challenges and debugging activities, students will learn to think logically and solve problems creatively."
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder3">
                      <div className="whatImage whatImage3"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__25"
                          defaultMessage={"Boost Digital Literacy:"}
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__26"
                          defaultMessage={
                            "Familiarity with the computer environment and software tools will empower students in their academic and personal lives."
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder4">
                      <div className="whatImage whatImage4"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__27"
                          defaultMessage={
                            "Enjoy a Supportive and Fun Learning Environment:"
                          }
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__28"
                          defaultMessage={
                            "The course is designed to be engaging and encouraging, ensuring students feel confident and motivated throughout their learning journey."
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                <div className="topRightWave"></div>
                <div className="topRightStar"></div>
                <div className="topRightCircle"></div>
              </div>
            </div>

            <div className="top bottom">
              <div className="left">
                <p className="whatP">
                  <FormattedMessage
                    id="CoursesDetails__29"
                    defaultMessage={"Parents can expect:"}
                  />
                </p>

                <div className="whatQuestions">
                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder5">
                      <div className="whatImage whatImage5"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__30"
                          defaultMessage={
                            "Regular Updates and Involvement Opportunities:"
                          }
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__31"
                          defaultMessage={
                            "Through orientation sessions, weekly updates, and a feedback mechanism, parents will be well-informed and involved in their child's learning process."
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder6">
                      <div className="whatImage whatImage6"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__32"
                          defaultMessage={
                            "A Safe and Nurturing Space for Learning:"
                          }
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__33"
                          defaultMessage={
                            "Qualified instructors and a carefully designed curriculum ensure a positive and productive learning environment for all students."
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="whatQuestion">
                    <div className="whatBorder whatBorder7">
                      <div className="whatImage whatImage7"></div>
                    </div>

                    <div className="whatRight">
                      <p>
                        <FormattedMessage
                          id="CoursesDetails__34"
                          defaultMessage={"Visible Progress and Development:"}
                        />
                      </p>
                      <span>
                        <FormattedMessage
                          id="CoursesDetails__35"
                          defaultMessage={
                            "Parents will witness their child's growth in technical skills, creativity, and confidence as they progress through the course."
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right">
                <div className="bottomRightmagnet"></div>
                <div className="bottomRightSquare"></div>
                <div className="bottomRightWave"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="CoursesDetails__6">
          <h1 className="CoursesDetailsTitle">
            <FormattedMessage
              id="CoursesDetails__36"
              defaultMessage={"Register now"}
            />
          </h1>

          <p className="uppercaseText">
            <FormattedMessage
              id="CoursesDetails__37"
              defaultMessage={"INFO ABOUT PARENT OR AUTHORIZED PERSON"}
            />
          </p>

          <div className="CoursesDetails__6__inside">
            <div className="ContactDetails Amount">
              <div className="Amount__6">
                <div className="insideAmount">
                  <div>
                    <label htmlFor="firstName">
                      <FormattedMessage
                        id="CoursesDetails__38"
                        defaultMessage={"First name"}
                      />
                    </label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <FormattedMessage id='Enter your first name'>
                                                {(msg) => (
                                                    <input 
                                                        type="text" id='firstname' name='firstname' 
                                                        value={firstname} onChange={(e) => setfirstname(e.target.value)} 
                                                        placeholder={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName">
                      <FormattedMessage
                        id="CoursesDetails__39"
                        defaultMessage={"Last Name"}
                      />
                    </label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <FormattedMessage id='Enter your last name'>
                                                {(msg) => (
                                                    <input 
                                                        type="text" id='lastname' name='lastname' 
                                                        value={lastname} onChange={(e) => setlastname(e.target.value)} 
                                                        placeholder={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Amount__6">
                <div className="insideAmount">
                  <div>
                    <label htmlFor="phoneNumber">
                      <FormattedMessage
                        id="CoursesDetails__40"
                        defaultMessage={"Phone number"}
                      />
                    </label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <FiPhone />
                      </span>
                      <FormattedMessage id='Enter your phone number'>
                                                {(msg) => (
                                                    <input 
                                                        type="number" id='phone' name='phone' 
                                                        value={phonenumber} onChange={(e) => setphonenumber(e.target.value)} 
                                                        placeholder={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email">
                      <FormattedMessage
                        id="CoursesDetails__41"
                        defaultMessage={"Email"}
                      />
                    </label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <MdOutlineEmail />
                      </span>
                      <FormattedMessage id='Enter your email'>
                                                {(msg) => (
                                                    <input 
                                                        type="email" id='email' name='email' 
                                                        value={email} onChange={(e) => setemail(e.target.value)} 
                                                        placeholder={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                    </div>
                  </div>
                </div>
              </div>

              <p className="uppercaseText">
                <FormattedMessage
                  id="CoursesDetails__42"
                  defaultMessage={"INFO ABOUT THE CHILD"}
                />
              </p>

              <div className="Amount__6">
                <div className="insideAmount">
                  <div>
                    <label htmlFor="firstName2">
                      {" "}
                      <FormattedMessage
                        id="CoursesDetails__38"
                        defaultMessage={"First name"}
                      />
                    </label>

                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <FormattedMessage id='Enter your first name'>
                                                {(msg) => (
                                                    <input 
                                                        type="text" id='firstname2' name='firstname2' 
                                                        value={firstname2} onChange={(e) => setfirstname2(e.target.value)} 
                                                        placeholder={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="lastName2">
                      <FormattedMessage
                        id="CoursesDetails__39"
                        defaultMessage={"Last name"}
                      />
                    </label>
                    <div className="spanAndInput people">
                      <span id="people">
                        <CiUser />
                      </span>
                      <FormattedMessage id='Enter your last name'>
                                                {(msg) => (
                                                    <input 
                                                        type="text" id='lastname2' name='lastname2' 
                                                        value={lastname2} onChange={(e) => setlastname2(e.target.value)} 
                                                        placeholder={msg}
                                                    />
                                                )}
                                            </FormattedMessage>
                    </div>
                  </div>
                </div>
              </div>

              <Link to={"/apply"} className="enroll">
                <FormattedMessage
                  id="CoursesDetails__43"
                  defaultMessage={"Enroll"}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CoursesDetails;
