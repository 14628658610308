import React, { useState } from "react";
import "./WhatPeopleSay.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import WhatPeopleSayTabPanel from "./WhatPeopleSayTabPanel";
import { FormattedMessage } from "react-intl";

const WhatPeopleSay = () => {
  const storedLocale = localStorage.getItem("locale");

  return (
    <div className="whatPeopleSay">
      <div className="eventsActivities-heading">
        <p>
          {storedLocale == "sq-AL" ? (
            <>
              Çfarë thonë&nbsp;
              <span className="yellowA">njerëzit</span>
              &nbsp;per ne
            </>
          ) : (
            <>
              <FormattedMessage id="wp1" /> &nbsp;
              <span className="yellowA">
                <FormattedMessage id="wp2" />
              </span>
              &nbsp;
              <FormattedMessage id="haveto" />
              &nbsp;
              <FormattedMessage id="wp3" />
            </>
          )}
        </p>
      </div>
      <Tabs className="eventsActivitiesDown">
        <div className="eaTablist">
          <TabList className="eactbl">
            <Tab className="eact">
              <p>
                <FormattedMessage id="tab1-1" />
              </p>
            </Tab>
            <Tab className="eact">
              <p>
                <FormattedMessage id="wpt1" />
              </p>
            </Tab>
            <Tab className="eact">
              <p>
                <FormattedMessage id="wpt2" />
              </p>
            </Tab>
            <Tab className="eact">
              <p>
                <FormattedMessage id="wpt3" />
              </p>
            </Tab>
          </TabList>
        </div>

        <div className="eaTabPanel">
          <TabPanel>
            <WhatPeopleSayTabPanel tabName={"All"} />
          </TabPanel>
          <TabPanel>
            <WhatPeopleSayTabPanel tabName={"Student"} />
          </TabPanel>
          <TabPanel>
            <WhatPeopleSayTabPanel tabName={"Teacher"} />
          </TabPanel>
          <TabPanel>
            <WhatPeopleSayTabPanel tabName={"Parent"} />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default WhatPeopleSay;
